import { render, staticRenderFns } from "./AddJcomOnlinePayment.vue?vue&type=template&id=9f06fd44&"
import script from "./AddJcomOnlinePayment.vue?vue&type=script&lang=js&"
export * from "./AddJcomOnlinePayment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports