<template>
  <div id="member-subscription-list">
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <!-- <h4>Receipts generated for successful payments.</h4> -->
        <!-- Click
        <v-icon icon size="25px" color="primary">
          mdi-account-multiple-plus
        </v-icon>
        button to add members -->
      </v-card-subtitle>

      <v-container class="py-0">
        <v-speed-dial v-if="FloatingButtonFlag" v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left"
          :direction="direction" :open-on-hover="hover" :transition="transition" :fixed="true">
          <template v-slot:activator>
            <v-btn v-model="fab" color="blue darken-2" dark fab>
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-icon v-else> mdi-account-circle </v-icon>
            </v-btn>
          </template>
          <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
          <v-btn fab dark small color="indigo" @click.prevent="addRecordPrompt = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
        </v-speed-dial>
      </v-container>
      <v-dialog v-model="addRecordPrompt" persistent max-width="600px">
        <add-jcom-online-payment pageDescription="Add JCOM Online Payment" :addRecordPrompt="addRecordPrompt"
          @hideAddRecordPrompt="hideAddRecordPrompt" v-if="addRecordPrompt"></add-jcom-online-payment>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader v-bind="attrs" type="table-tbody"></v-skeleton-loader>
        </v-container>
        <v-container>
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    Subscription Type
                    <small>(optional)</small>
                  </h6>
                </label>
                <v-autocomplete :reduce="(option) => option.value" :loading="SubscriptionTypeOptionsLoading"
                  :items="SubscriptionTypeOptions" :rules="SubscriptionTypeRules" v-model="SubscriptionType"
                  hint="Select a subscription type" persistent-hint dense clearable required outlined></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <h6>Payment Id <small>(optional)</small></h6>
                <v-text-field v-model="PaymentId" :rules="PaymentIdRules" placeholder="Payment Id"
                  hint="Enter razorpay payment id" persistent-hint required dense outlined></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    Zone
                    <small>(optional)</small>
                  </h6>
                </label>
                <v-autocomplete @change="getJcomTableOptions" :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading" :items="ZoneCodeOptions" :rules="ZoneCodeRules" v-model="ZoneCode"
                  hint="Select a Zone" persistent-hint dense clearable required outlined></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>
                    JCOM Table
                    <small>(optional)</small>
                  </h6>
                </label>
                <v-autocomplete :reduce="(option) => option.value" :loading="JcomTableOptionsLoading"
                  :items="JcomTableOptions" :rules="JcomTableRules" v-model="JcomTable" hint="Select a JCOM Table"
                  persistent-hint dense clearable required outlined></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>From Date</h6>
                </label>
                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" lazy
                  transition="scale-transition" offset-y full-width min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field required v-model="FromDate" label="From Date" hint="Select a From Date" persistent-hint
                      readonly v-on="on" background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid" validate-on-blur solo dense></v-text-field>
                  </template>
                  <v-date-picker v-model="FromDate" @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6>To Date</h6>
                </label>
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" lazy
                  transition="scale-transition" offset-y full-width min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field required v-model="ToDate" label="To Date" hint="Select a To Date" persistent-hint
                      readonly v-on="on" background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid" validate-on-blur solo dense></v-text-field>
                  </template>
                  <v-date-picker v-model="ToDate" @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn :disabled="!valid1" @click.prevent="searchForm" :loading="SearchFlag" color="#8950FC"
                  elevation="30" shaped tile large class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text">
                  Search
                </v-btn>
                <v-btn v-if="RoleId == 2" @click.prevent="addRecordPrompt = true" color="#8b008b" elevation="30" shaped
                  tile large class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text">
                  Add Online Payment
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="!StartupLoadingFlag && ResultFlag && tableData1.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h3>{{ tableData1.length }} records found.</h3>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-btn v-if="tableData1.length > 0 && DownloadFlag" color="info" @click.prevent="
                generateExcel(
                  tableData1,
                  tableOptions1.ExcelFields,
                  tableOptions1.ExcelFileName,
                  'My Worksheet'
                )
                " class="mr-4">Download</v-btn>
              <!-- <json-excel
                v-if="tableData1.length > 0 && DownloadFlag"
                :name="ExcelFileName"
                :data="tableData1"
                :fields="ExcelFields"
              >
                <v-btn color="info" class="mr-4"> Download </v-btn>
              </json-excel> -->
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search records here" single-line
                hide-details></v-text-field>
              <br />
              <v-data-table class="elevation-1" loading-text="Loading... Please wait" v-model="selected" :search="search"
                :loading="TableLoadingFlag" :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag" :headers="tableColumns1" :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage" :footer-props="tableOptions1.FooterProps">
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip :color="getActiveStatusColor(item.ActiveStatusTxt)" draggable dark>{{ item.ActiveStatusTxt
                  }}</v-chip>
                </template>
                <template v-slot:item.ProfilePic="{ item }">
                  <img width="100" height="100" :src="item.ProfilePic" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.ReceiptFileFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :href="item.ReceiptFilePath" target="_blank" class="mx-2" icon dark small color="#EDBE38"
                        v-bind="attrs" v-on="on">
                        <v-icon dark> mdi-receipt </v-icon>
                      </v-btn>
                    </template>
                    <span> Receipt Download </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.MemberImageFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :href="item.MemberImagePath" target="_blank" class="mx-2" icon dark small color="blue"
                        v-bind="attrs" v-on="on">
                        <v-icon dark> mdi-image-area </v-icon>
                      </v-btn>
                    </template>
                    <span> Member Image Download </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.ActiveStatus == 2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-2" icon size="25px" color="blue" @click="previewData(item, e)" v-bind="attrs"
                        v-on="on">
                        mdi-clipboard-text-search
                      </v-icon>
                    </template>
                    <span> Preview </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.RemainingCount >= 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon icon size="25px" color="primary" @click="previewData(item, e)" v-bind="attrs" v-on="on">
                        mdi-account-multiple-plus
                      </v-icon>
                    </template>
                    <span> Add members </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import AddJcomOnlinePayment from "@/view/pages/erp/reports/jcom-report/AddJcomOnlinePayment.vue";

export default {
  mixins: [common],
  components: {
    AddJcomOnlinePayment,
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      TableLoadingFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,
      SearchFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      rows: [],
      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      zone: {},
      lom: {},

      name: "",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      select: "Item 1",
      items: ["Item 1", "Item 2", "Item 3", "Item 4"],
      checkbox: false,

      SubscriptionTypeRules: [],
      SubscriptionType: "",
      SubscriptionTypeOptions: [],
      SubscriptionTypeOptionsLoading: false,

      PaymentId: "",
      PaymentIdRules: [],

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      JcomTableRules: [],
      JcomTable: "",
      JcomTableOptions: [],
      JcomTableOptionsLoading: false,

      FromDate: "",
      FromDateRules: [(v) => !!v || "From date is required"],
      menu1: false,

      ToDate: "",
      ToDateRules: [(v) => !!v || "To date is required"],
      menu2: false,

      SearchId: "",
      SearchIdRules: [(v) => !!v || "Search Type is required"],
      SearchIdOptions: [
        { value: "", text: "--Select Type--" },
        { value: 1, text: "By LOM" },
        { value: 2, text: "By Membership Id" },
      ],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      YearHalfId: "",
      YearHalfIdRules: [(v) => !!v || "Year Half is required"],
      YearHalfIdOptions: [
        { value: "", text: "--Select Half--" },
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      YearIdRules: [(v) => !!v || "Year is required"],
      YearId: "",
      YearIdOptions: [],
      YearIdOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
      RoleId: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var CurrentYearId = this.$session.get("CurrentYearId");
        CurrentYearId = 1;
        CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
        this.CurrentYearId = CurrentYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        // RoleId = 4;
        this.RoleId = RoleId;

        var todayDate = new Date().toISOString().slice(0, 10);
        console.log(todayDate);

        this.FromDate = todayDate;
        this.ToDate = todayDate;

        this.getZoneCodeOptions();
        this.getSubscriptionTypeOptions();
      }
    },
    SubscriptionTypeOptions: function () {
      console.log("watch SubscriptionTypeOptions");
      this.SubscriptionTypeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    JcomTableOptions: function () {
      console.log("watch JcomTableOptions");
      this.JcomTableOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    previewData(tr) {
      console.log("previewData called");
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var url = "/members/subscription/assign";
        var id = tr.LomPaymentId;
        console.log("id=" + id);
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one lom to preview";
        this.toast("error", message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      this.resetForm();
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_subscription_payment",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getSubscriptionTypeOptions() {
      console.log("getSubscriptionTypeOptions called");
      this.SubscriptionType = "";
      this.SubscriptionTypeOptionsLoading = true;
      var selectbox1_source = "SubscriptionType";
      var selectbox1_destination = "SubscriptionTypeOptions";
      var selectbox1_url = "api/jcom/plan/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getJcomTableOptions() {
      console.log("getJcomTableOptions called");
      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      if (ZoneCode != "") {
        var selectbox1_source = "JcomTable";
        var selectbox1_destination = "JcomTableOptions";
        var selectbox1_url = "api/jcom/table/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          Zone: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        // this.toast("error", message);
      }
    },
    getYearCodeOptions(source, destination) {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = 1;
      console.log(
        "source=" +
        source +
        ", destination=" +
        destination +
        ", CurrentYearId=" +
        CurrentYearId
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/jci-year/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form2.validate();
      var FromDate = this.FromDate;
      var ToDate = this.ToDate;
      console.log(
        "validate1=" + validate1 + ", FromDate=" + FromDate + ", ToDate=" + ToDate
      );

      if (validate1 && FromDate != "" && ToDate != "") {
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.SearchFlag = true;
        this.tableData1 = [];
        this.zone = {};
        this.lom = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/order/lists";
        var upload = {
          UserInterface: 1,
          SubscriptionType: this.SubscriptionType,
          PaymentId: this.PaymentId,
          Zone: this.ZoneCode,
          JcomTable: this.JcomTable,
          FromDate: this.FromDate,
          ToDate: this.ToDate,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            var options = records.TableOptions;
            thisIns.tableOptions1 = options;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableData1 = records.TableData;
              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;

              thisIns.toast("success", output);
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (FromDate == "") {
          message += "From Date should not be empty. ";
        }
        if (ToDate == "") {
          message += "To Date should not be empty. ";
        }
        // this.toast("error", message);
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#member-subscription-list {
  .v-btn--floating {
    position: relative;
  }

  .v-speed-dial--bottom {
    bottom: 12%;
  }

  .v-speed-dial--right {
    right: 5%;
  }

  .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
    font-size: 15px;
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    font-size: 14px;
  }
}
</style>
